import { Typography, colorText } from '@/shared';
import { ColorContext } from '@/shared/providers/ColorContext';
import { FeaturedProjectSectionStoryblok } from '@/shared/types/storyblok/storyblok-types';
import clsx from 'clsx';
import Link from 'next/link';
import { useContext } from 'react';
import { ProjectsStoryblok } from '../../shared/types/storyblok/storyblok-types';
import Container from '../Container/Container';
import { SBImage } from '../ImageService/SBImage';
import styles from './FeaturedProjectSection.module.scss';

const FeaturedProjectsSection = (props: FeaturedProjectSectionStoryblok) => {
    let hexColor = useContext(ColorContext);
    if (props.isDark !== undefined) {
        hexColor = `var(--yellow)`;
    }
    const content = props.blok?.content ?? props.content;
    const isDark = props.blok?.isDark ?? props.isDark;

    return (
        <Container
            code="large"
            blok={props.blok ?? props}
            className={{
                wrap: styles.container,
                root: clsx(styles.root, {
                    [styles.dark]: isDark,
                }),
            }}
        >
            {content.map((item: ProjectsStoryblok) => {
                if (!item.content) return null;
                const { mainImage: image, clientName, customTitle } = item.content;
                const title = colorText(hexColor, customTitle, isDark ? false : true || '');
                return (
                    <Link className={styles.card} key={item.uuid} href={item.full_slug}>
                        {image.filename && <SBImage image={image} height={256} fit="cover" />}
                        <div className={styles.info}>
                            <Typography.Small className={styles.preTitle}>{clientName}</Typography.Small>
                            <Typography.H3 className={styles.title} darkTheme={!isDark}>
                                <div dangerouslySetInnerHTML={{ __html: title }} />
                            </Typography.H3>
                        </div>
                    </Link>
                );
            })}
        </Container>
    );
};

export default FeaturedProjectsSection;
